import React from "react"
import {
  AppBar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material"
import { Menu as MenuIcon } from "react-feather"

export interface HeaderProps {}

const NAV = [
  {
    label: "Accueil",
    href: "https://www.reachmaker.com",
  },
  {
    label: "Manifesto",
    href: "https://www.reachmaker.com/manifesto",
  },
  {
    label: "Reach Calculator",
    href: "https://calculator.reachmaker.com",
  },
  { label: "Tarifs", href: "https://www.reachmaker.com/tarifs" },
  { label: "Podcast", href: "https://smartlink.ausha.co/growthmarketing" },
]

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <a href="https://reachmaker.com">
            <img
              alt="Logo Reachmaker"
              src="https://uploads-ssl.webflow.com/6161707a1289df8655a91df2/617ea4123f776e54ef6dafe6_Capture_d_e%CC%81cran_2021-10-14_a%CC%80_10.04.20-removebg-preview.png"
              width={198}
            />
          </a>
          <Hidden mdDown>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexGrow={1}
              width={"100%"}
            >
              {NAV.map(({ label, href }) => (
                <Button color="inherit" href={href} key={label}>
                  {label}
                </Button>
              ))}
            </Box>
            <Box width={198}>
              <Button
                href="https://app.reachmaker.com"
                color="secondary"
                variant="contained"
                size="small"
              >
                Connexion
              </Button>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              flexGrow={1}
              width={"100%"}
            >
              <IconButton onClick={handleClick}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {NAV.map(({ label, href }) => (
                  <MenuItem key={label} onClick={handleClose} href={href}>
                    {label}
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem href="https://app.reachmaker.com">Connexion</MenuItem>
              </Menu>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Divider />
    </>
  )
}
